import { useContext, useState } from 'react';
import Pagination from '../components/Pagination/Pagination';
import FollowUs from '../components/utils/FollowUs';
import GeneratePost from '../components/posts/GeneratePost';
import Headline from '../components/utils/Headline';
import Search from '../components/utils/Search';
import NewsletterSubscribe from '../components/Newsletter/NewsletterSubscribe';
import GlobalContext from '../contexts/GlobalContext';
import Spinner from '../components/Spinner';

import './blog.css';
import { useBlogPosts } from '../hooks/useRequest';

const BlogPage = () => {
  const [searchVal, setSearchVal] = useState('');
  const [cursor, setCursor] = useState({});
  const { globalContext } = useContext(GlobalContext);
  const defaultPosts = globalContext.posts;
  const { isError, isLoading, data } = useBlogPosts(cursor, searchVal);
  const posts = data?.posts?.edges || [];
  const { hasNextPage, endCursor } = data?.posts?.pageInfo || {};

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <main className="error"><div>Posts not found!</div></main>;
  }

  return (
    <>
      <section className='header-section blog'>
        <div className="container">
          <Headline title="Blog" searchVal={searchVal} />
        </div>
      </section>
      <section className='blog-section container'>
        <div className="blog-post">
          <GeneratePost posts={posts} type="medium" shape="horizontal" />
          <div className='pagination'>
            <Pagination
              endCursor={endCursor}
              hasNextPage={hasNextPage}
              setCursor={setCursor}
            />
          </div>
        </div>
        <aside className="right-sidebar">
          <Search
            searchVal={searchVal}
            setSearchVal={setSearchVal}
          />
          <div className="blog-recent">
            <h4 className='title'>Recent Posts</h4>
            <div className="recent-posts">
              <GeneratePost posts={defaultPosts} count={6} type="tiny" />
            </div>
          </div>
          <div className="blog-newsletter">
            <NewsletterSubscribe />
          </div>
          <FollowUs classes="blog-follow" />
        </aside>
      </section>
    </>
  );
};

export default BlogPage;
