import { NavLink } from 'react-router-dom';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon
} from '../../assets/icons';

const FollowUs = ({ classes }) => (
    <div className={classes}>
      <h4>Follow Us</h4>
      <ul className='social-icon'>
        <li className='twitter'>
          <NavLink
            to="https://twitter.com/ajayv128/"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer">
              <TwitterIcon className="icon" />
            </NavLink>
        </li>
        <li className='facebook'>
          <NavLink
            to="https://www.facebook.com/ajayv128/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer">
              <FacebookIcon className="icon" />
          </NavLink>
        </li>
        <li className='linkedin'>
          <NavLink to="https://www.linkedin.com/in/ajayv1/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon className="icon" />
          </NavLink>
        </li>
        <li className='insta'>
          <NavLink to="https://medium.com/@ajayverma_5579/" aria-label="Medium" target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="icon" />
          </NavLink>
        </li>
      </ul>
    </div>
);

export default FollowUs;
