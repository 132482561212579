import './App.css';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './routes';
import { GlobalContextProvider } from './contexts/GlobalContext';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = () => {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  }));
  const initialState = {
    appTitle: 'Baba the best',
    posts: [],
    categories: []
  };
  const [globalContext, setGlobalContext] = useState(initialState);

  return (
    <GlobalContextProvider value={{ globalContext, setGlobalContext }}>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </GlobalContextProvider>
  );
};

export default App;
