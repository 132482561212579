import { useQuery } from "react-query";
import { GraphQLClient, gql } from "graphql-request";
import { config } from '../config';

const graphQLClient = new GraphQLClient(config.GRAPH_QL_API_URL, {
  headers: {}
});

export function useCategoryPosts(category, cursors) {
  return useQuery(["get-category-posts", category, cursors], async () => {
    const { posts } = await graphQLClient.request(gql`
      query {
        posts(first: 5, after: "${cursors.after}", where: {categoryName: "${category}"}) {
          edges {
            node {
              id
              slug
              title
              link
              excerpt
              dateGmt
              featuredImage {
                node {
                  sourceUrl
                  altText
                }
              }
              author {
                node {
                  name
                }
              }
              categories {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            startCursor
            hasPreviousPage
          }
        }
      }
    `, { category, cursors });

    return { posts };
  });
}

export function useBlogPosts(cursors, searchVal) {
  return useQuery(["get-blog-posts", cursors, searchVal], async () => {
    const { posts } = await graphQLClient.request(gql`
      query {
        posts(first: 10, after: "${cursors.after}", where: {search: "${searchVal}"}) {
          edges {
            node {
              id
              slug
              title
              link
              excerpt
              dateGmt
              featuredImage {
                node {
                  sourceUrl
                  altText
                }
              }
              author {
                node {
                  name
                }
              }
              categories {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            startCursor
            hasPreviousPage
          }
        }
      }
    `, { cursors, searchVal });

    return { posts };
  });
}

export function useGetPosts() {
  return useQuery("get-posts", async () => {
    const { posts, categories } = await graphQLClient.request(gql`
      query {
        posts(first: 10, after: "") {
          edges {
            node {
              id
              slug
              title
              link
              excerpt
              dateGmt
              featuredImage {
                node {
                  sourceUrl
                  altText
                }
              }
              author {
                node {
                  name
                }
              }
              categories {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
          pageInfo {
            startCursor
            hasNextPage
          }
        }

        categories {
          nodes {
            name
            count
          }
        }
      }
    `);

    return { posts, categories };
  });
};

export function useGetCategories() {
  return useQuery("get-categories", async () => {
    const { categories } = await graphQLClient.request(gql`
      query {
        categories {
          nodes {
            name
            count
          }
        }
      }
    `);

    return categories.nodes;
  });
};

export function useGetPost(slug) {
  return useQuery(["get-post", slug], async () => {
    const res = await graphQLClient.request(
      gql`
        query {
          post(
            id: "${slug}"
            idType: SLUG
          ) {
            id
            title
            content
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            author {
              node {
                name
              }
            }
            dateGmt
            categories {
              nodes {
                name
              }
            }
          }
        }
      `,
      { slug }
    );
    return res;
  });
};