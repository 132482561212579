import './footer.css';
import FollowUs from '../../components/utils/FollowUs';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import GlobalContext from '../../contexts/GlobalContext';
import NewsletterSubscribe from '../../components/Newsletter/NewsletterSubscribe';
import logo from '../../assets/logo.webp';

const Footer = () => {
  const { globalContext } = useContext(GlobalContext);
  const { categories } = globalContext;

  return (
    <footer className='footer'>
      <div className='footer-container flex flex-sb'>
        <div className="footer-box box-1">
          <figure>
            <NavLink to="/">
              <img src={logo} alt="Logo" />
            </NavLink>
          </figure>
          <p>Get the latest news and articles on being healthy, wealthy, motivational stuff and personal well being.</p>
        </div>
        <div className="footer-box box-2">
          <h4 className='title'>Categories</h4>
          <div className="footer-cat">
            {
              categories.map((category, index) => <NavLink
                key={index}
                className="footer-ctg"
                to={'/category/' + category.name.toLowerCase()}
              >{category.name}</NavLink>)
            }
          </div>
        </div>
        <div id="join" className="footer-box box-3">
          <NewsletterSubscribe />
        </div>
        <FollowUs classes="footer-box box-4" />
      </div>
      <div className="footer-bottom">
        <div className="container flex flex-sb">
          <p>Copyright 2023 - babathebest.com</p>
          <nav className='footer-menu'>
            <ul className='flex'>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
