import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Main from './layout/Main';
import AboutUsPage from './pages/AboutUsPage';
import BlogPage from './pages/BlogPage';
import CategoryPage from './pages/CategoryPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import PostPage from './pages/PostPage';

const AppRoutes = () => (
    <Router>
      <Header />
      <Main>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/blog" element={<BlogPage />} />
          <Route exact path="/category/:cat1/:cat2" element={<CategoryPage />} />
          <Route exact path="/category/:cat1" element={<CategoryPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/:slug" element={<PostPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Main>
      <Footer />
    </Router>
);

export default AppRoutes;
