import { FacebookIcon, LinkedInIcon, TwitterIcon } from '../../assets/icons';
import './share.css';

const Share = () => (
    <div className='share-container post-container'>
      <p>Please share the articles on - </p>
      <div className="social-btns">
        <div className="social-btn facebook">
          <a
            href="https://www.facebook.com/ajayv128/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <div className="social-btn-text">
            <span className='brand'>Facebook</span>
          </div>
        </div>
        <div className="social-btn twitter">
          <a
            href="https://twitter.com/"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer">
            <TwitterIcon />
          </a>
          <div className="social-btn-text">
            <span className='brand'>Twitter</span>
          </div>
        </div>
        <div className="social-btn linkedin">
          <a href="https://www.linkedin.com/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <div className="social-btn-text">
            <span className='brand'>LinkedIn</span>
          </div>
        </div>
      </div>
    </div>
);

export default Share;
