import { useContext } from 'react';
import Posts from '../components/posts/Posts';
import GlobalContext from '../contexts/GlobalContext';

import './home.css';
import homeImg from '../assets/undraw_mind.svg';

const HomePage = () => {
  const { globalContext } = useContext(GlobalContext);
  const { posts } = globalContext;
  const handleJoinClick = () => {
    const element = document.getElementById('join');
    const input = document.querySelector('#join input');

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        input.focus();
      }, 1000);
    }
  };
  const len = posts.length > 9 ? 9 : posts.length;

  return (
    <>
      <section className='site-tag container'>
        <div className="site-tag-img">
          <img
            src={homeImg}
            alt='Be calm and focus on your goal.' 
          />
        </div>
        <div className='site-tag-heading'>
          <h1 className='heading quote'>Be calm and focus.</h1>
          <p className='sub-heading'>Get the latest articles on lifestyle, wealth, motivation and other matters every weekend directly to your inbox.</p>
          <button onClick={handleJoinClick} className='btn site-tag-btn'>Join Now</button>
        </div>
      </section>
      <Posts title="Latest Guides & Articles" posts={posts} count={len} isMason />
    </>
  );
};

export default HomePage;
