import { Link } from 'react-router-dom';

const Headline = ({
  title, postTitle, searchVal, totalSearchCount, cat1, cat2
}) => {
  const catEl = () => {
    let arr = [];

    if (cat1) {
      arr = [
        <Link key={0} className='white' to="/blog">Blog</Link>,
        <span key={1}> » </span>,
        <Link key={2} className='white' to={`/category/${cat1}`}>{cat1}</Link>
      ];
    }

    if (cat2) {
      arr.push(<span key={3}> » {cat2}</span>);
    }

    return arr;
  };

  return (
    <header className="page-header">
      {
        searchVal ? <>
          <h1 className='headline'>{totalSearchCount} Result for &ldquo;{searchVal}&rdquo;</h1>
        </> : <h1 className='headline'>{title}</h1>
      }
      {
        postTitle && <div>
          <Link className='white' to="/blog">Blog</Link>
          <span> » </span>
          <span dangerouslySetInnerHTML={{__html: postTitle}} />
        </div>
      }
      {
        cat1 && <div>{catEl()}</div>
      }
    </header>
  );
};

export default Headline;
