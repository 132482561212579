/* eslint-disable max-len */
import Headline from '../components/utils/Headline';

import './about.css';

const AboutUsPage = () => (
    <>
      <section className='about header-section'>
        <div className="container">
          <Headline title="About Us" />
        </div>
      </section>
      <section className="about-content container">
        <p>At Babathebest, our main goal is to provide authentic helpful articles on health, wealth and personal well beings.</p>
        <h2>Why babathebest (our story)?</h2>
        <p>I am sure everyone have heard the stories about how to live a life, what to eat, and how to earn money throughout their life from their father, grandfather or someone knowledgeable.</p>
        <p>
          In india, we call our grandfather as `baba`, the name of the site is taken from there only and of course they are the best hence babathebest.
        </p>
        <p>This site is packed with full of inspirational articles along with some random stuff.</p>
        <p>If you have any questions about Babathebest, then please don’t hesitate to <a className='red' href="/contact-us">contact us</a>.</p>
      </section>
    </>
);

export default AboutUsPage;
