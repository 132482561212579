import { useParams } from 'react-router-dom';
import Headline from '../components/utils/Headline';
import { getDate } from '../utils/dateUtils';
import PageNotFound from './PageNotFound';
import Share from '../components/utils/Share';
import { Calendar, CircleUser, HotMug } from '../assets/icons';
import { useGetPost } from '../hooks/useRequest';

import './post.css';
import Spinner from '../components/Spinner';

const PostPage = () => {
  const { slug } = useParams();
  const {
    isError, isLoading, data
  } = useGetPost(slug);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <main className="error"><div>Posts not found!</div></main>;
  }

  if (data && !data.post) {
    return <PageNotFound />;
  }

  const postData = data.post;
  const {
    // eslint-disable-next-line camelcase
    title, author, featuredImage, dateGmt, content
  } = postData;

  const estTime = Math.floor(Math.random(0, 1) * 7);

  return (
    <>
      <section className='header-section'>
        <div className="container">
          <Headline title="Blog" postTitle={title} />
        </div>
      </section>
      <section className='post-container feature-image-container'>
        <img className='post-img' src={featuredImage?.node.sourceUrl} alt={featuredImage?.node.altText} />
        <div className="post-header">
          <h2 itemProp="headline" dangerouslySetInnerHTML={{__html: title}} />
        </div>
        <ul className='post-meta'>
          <li className="meta-author" itemProp="name">
            <CircleUser />{author.node.name}
          </li>
          <li className="meta-mod-date" itemProp="dateModified">
            <Calendar />{getDate(dateGmt)}
          </li>
          <li className="meta-cat">
            <HotMug />{estTime} min read
          </li>
        </ul>
      </section>
      <section className="post-container" dangerouslySetInnerHTML={{ __html: content }}>
      </section>
      <Share />
    </>
  );
};

export default PostPage;
