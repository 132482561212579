import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm';
import { config } from '../../config';

const NewsletterSubscribe = () => {
  const MAILCHIMP_URL = config.MAILCHIMP_PUBLIC_URL;

  return (
    <MailchimpSubscribe
      url={ MAILCHIMP_URL }
      render={ (props) => {
        const { subscribe, status, message } = props || {};

        return (
          <NewsletterForm
            status={ status }
            message={ message }
            onValidated={ (formData) => subscribe(formData) }
          />
        );
      } }
    />
  );
};

export default NewsletterSubscribe;
