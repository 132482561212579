import { NavLink } from 'react-router-dom';
import { CommentIcon } from '../../assets/icons';
import { getDate } from '../../utils/dateUtils';

// eslint-disable-next-line consistent-return
const MediumPost = ({ post, shape }) => {
  let categories = post.categories.edges.map((cat) => cat?.node.name.toLowerCase());
  let comments = post.comment_status === 'closed' ? 'comments off' : '0 comments';
  const postLink = `/${post.slug}`;
  let cat0 = categories[0];
  let cat1 = categories[1] || '';
  let excerpt = post.excerpt.split(' ').slice(0, 12).join(' ');
  
  excerpt += '...';

  if (shape === 'horizontal') {
    return (
      <div className='post'>
        <NavLink className='img-container' to={postLink}>
          <img src={post.featuredImage.node.sourceUrl} alt={post.title}/>
        </NavLink>
        <div className='blog-content'>
          <p className='category'>
            <NavLink to={'/category/' + cat0}>{cat0}</NavLink>
            {
              cat1 && <>
                <span className='black'> / </span>
                <NavLink to={'/category/' + cat0 + '/' + cat1}>{cat1}</NavLink>
              </>
            }
          </p>
          <h3 className='post-title'>
            <NavLink to={postLink} dangerouslySetInnerHTML={{__html: post.title}} />
          </h3>
          <div className='post-excerpt' dangerouslySetInnerHTML={{ __html: excerpt }}></div>
          <div className="post-date">
            <p><span><CommentIcon /></span>{comments}</p>
            <p>{getDate(post.dateGmt)}</p>
          </div>
        </div>
      </div>
    );
  }

  if (shape === 'vertical') {
    return (
      <div className='post post-card' key={post.id}>
        <NavLink to={`/${post.slug}`} className="post-thumbnail">
          <img src={post.featuredImage.node.sourceUrl} alt={post.title}/>
        </NavLink>
        <NavLink className='category' to={'/category/' + cat0}>{cat0}</NavLink>
        <div className="post-text">
          <h3 className='post-title'>
            <NavLink to={`/${post.slug}`} dangerouslySetInnerHTML={{__html: post.title}} />
          </h3>
          <p className='post-excerpt' dangerouslySetInnerHTML={{ __html: excerpt }}></p>
          <NavLink className='read-more' to={`/${post.slug}`}>Read More »</NavLink>
        </div>
        <div className='meta-container'>
          <span className='author'>{post.author.node.name}</span>
          <span className='date'>{getDate(post.dateGmt)}</span>
        </div>
      </div>
    );
  }
};

export default MediumPost;
