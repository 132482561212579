const monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getDate = function (dateStr) {
  let dateObj = new Date(dateStr);
  let date = dateObj.getDate();
  let month = dateObj.getMonth();
  let year = dateObj.getFullYear();

  return monthArr[month] + ' ' + date + ', ' + year;
};
