import Headline from '../components/utils/Headline';

import './contact.css';

const ContactPage = () => (
    <>
      <section className='header-section contact'>
        <div className="container">
          <Headline title="Contact Us" />
        </div>
      </section>
      <section className='contact container'>
        <div className="contact-form">
          <form>
            <h2>
              Write
              <br />
              Me.
            </h2>
            <p>Write us at <a className='red' href="mailto:support@babathebest.com">support@babathebest.com</a> or send the below form to get the technical help. <br /> We will respond as soon as possible.</p>
            <div className='form-control flex'>
              <div className='w33'>
                <label htmlFor='full-name'>Full Name</label>
                <input type="text" name="full-name" id="fullName" required />
              </div>
              <div className='w33'>
                <label htmlFor='email'>Email Address</label>
                <input type="email" name="email" id="email" required />
              </div>
              <div className='w33'>
                <label htmlFor='phone'>Phone</label>
                <input type="number" name="phone" id="phone" maxLength={10} />
              </div>
            </div>
            <div className="form-control">
              <label htmlFor='message'>Message</label>
              <textarea name="message" id="message" cols="30" rows="10"></textarea>
            </div>
            <div className="form-control btn-holder">
              <button type='submit' className='button'>Send</button>
            </div>
          </form>
        </div>
      </section>
    </>
);

export default ContactPage;
