import MediumPost from './MediumPost';
import TinyPost from './TinyPost';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const GeneratePost = ({
  posts, count, type, shape, isMason
}) => {
  const postArr = (count && posts?.length >= count) ? posts.slice(0, count) : posts;

  if (type === 'tiny') {
    return (
      <>
        {
          postArr.map((post) => <TinyPost key={post.node.id} post={post.node} />)
        }
      </>
    );
  }

  if (type === 'medium') {
    if (isMason) {
      return (
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <Masonry gutter="30px">
            {
              postArr.map((post) => <MediumPost
                  key={post.node.id}
                  post={post.node}
                  shape={shape}
                />)
            }
          </Masonry>
        </ResponsiveMasonry>
      );
    }

    return (
      <>
        {
          postArr.map((post) => <MediumPost
              key={post.node.id}
              post={post.node}
              shape={shape}
            />)
        }
      </>
    );
  }

  return (
    <></>
  );
};

export default GeneratePost;
