import './posts.css';
import GeneratePost from './GeneratePost';

const Posts = ({ title, posts, isMason, count=6 }) => (
    <section className='posts-section'>
      <div className='container'>
        <h2 className='posts-section-heading'>{title}</h2>
        <div className='posts-container'>
          <GeneratePost posts={posts} count={count} type="medium" shape="vertical" isMason={isMason} />
        </div>
      </div>
    </section>
);

export default Posts;
