import { getDate } from '../../utils/dateUtils';

import './tinypost.css';

const TinyPost = ({ post }) => {
  const postLink = `/${post.slug}`;

  return (
    <div className='post'>
      <a className='img-container' href={postLink}>
        <img src={post.featuredImage.node.sourceUrl} alt={post.title} />
      </a>
      <div className=''>
        <a className='title' href={postLink} dangerouslySetInnerHTML={{__html: post.title}} />
        <p className='date'>{getDate(post.dateGmt)}</p>
      </div>
    </div>
  );
};

export default TinyPost;
