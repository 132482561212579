import { useContext, useState } from 'react';
import Pagination from '../components/Pagination/Pagination';
import GeneratePost from '../components/posts/GeneratePost';
import Headline from '../components/utils/Headline';
import { NavLink, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';

import './category.css';
import GlobalContext from '../contexts/GlobalContext';
import { useCategoryPosts } from '../hooks/useRequest';

const CategoryPage = () => {
  const { cat1, cat2 } = useParams();
  const { globalContext } = useContext(GlobalContext);
  let categories = globalContext.categories;
  let category = cat1;
  
  if (cat2) {
    category = ',' + cat2;
  }

  const [cursor, setCursor] = useState({});
  const { isError, isLoading, data } = useCategoryPosts(category, cursor);
  const posts = data?.posts?.edges || [];
  const { hasNextPage, endCursor, hasPreviousPage, startCursor } = data?.posts?.pageInfo || {};

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <main className="error"><div>No Results found!</div></main>;
  }

  return (
    <>
      <section className='header-section blog'>
        <div className="container">
          <Headline title={cat2 || cat1} cat1={cat1} cat2={cat2} />
        </div>
      </section>
      <section className='blog-section container'>
        <div className="blog-post">
          {
            posts.length === 0 ? <div>No Results found!</div> : <>
              <GeneratePost posts={posts} type="medium" shape="horizontal" />
              <div className='pagination'>
                <Pagination
                  endCursor={endCursor}
                  hasNextPage={hasNextPage}
                  startCursor={startCursor}
                  hasPreviousPage={hasPreviousPage}
                  setCursor={setCursor}
                />
              </div>
            </>
          }
        </div>
        <aside className="right-sidebar">
          <div className="blog-recent">
            <h4 className='title'>All Categories</h4>
            <div className="category-container">
              {
                categories.map((category, index) => <NavLink
                  key={index}
                  className="ctg"
                  to={'/category/' + category.name.toLowerCase()}
                >{category.name}</NavLink>)
              }
            </div>
          </div>
        </aside>
      </section>
    </>
  );
};

export default CategoryPage;
