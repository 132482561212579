import { RightIcon } from '../../assets/icons';
import './pagination.css';

const Pagination = ({ 
  hasNextPage, endCursor, setCursor
}) => {

  const handlePagination = (ev) => {
    ev.preventDefault();

    const { type } = ev.currentTarget.dataset || {};

    if (type === 'inc') {
      setCursor((old) => {
        return {
          ...old,
          after: endCursor
        }
      });
    }
  }

  return (
    <ul className="page-numbers">
      <li>
        <span className={`next page-numbers ${!hasNextPage ? 'hide' : ''}`}
          data-type="inc"
          onClick={handlePagination}>
          <RightIcon />
        </span>
      </li>
    </ul>
  );
};

export default Pagination;
