import { useState } from 'react';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError('Please enter a valid email address');

      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf('@') > -1 && isFormValidated;
  };
  /**
   * Handle Input Key Event.
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);

    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };
  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (resMessage) => {
    if (!resMessage) {
      return null;
    }

    return resMessage;
  };

  return (
    <>
      <h4 className='title'>Newsletter</h4>
      <p>Subscribe to get the latest articles directly in your inbox. Don&apos;t worry we never spam you.</p>
      <div className="email-wrap">
        <input
          aria-label="Enter your email address to subscribe"
          type="email"
          placeholder="Your Email"
          name="EMAIL"
          className="required email"
          onChange={(event) => setEmail(event?.target?.value ?? '')}
          onKeyUp={(event) => handleInputKeyEvent(event)}
        />
        <button
          aria-label="Submit email address"
          type="submit"
          value=""
          name="subscribe"
          onClick={handleFormSubmit}
          className="button news-cta">
            Go
        </button>
      </div>
      {status === 'error' || error ? (
        <div
          className="red f-14"
          dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
        />
      ) : null }
      {status === 'success' && status !== 'error' && !error && (
        <div className="success" dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </>
  );
};

export default NewsletterForm;
