import { NavLink } from 'react-router-dom';
import './header.css';
import logo from '../../assets/logo.webp';

const Header = () => {
  const handleToggleClick = (e) => {
    const target = e.currentTarget;

    if (target.nodeName === 'UL') {
      target.parentElement.classList.toggle('active');
    } else {
      target.nextSibling.classList.toggle('active');
    }
  };

  return (
    <header className="app-header">
      <nav className='navbar'>
        <figure className="brand-logo">
          <NavLink to="/">
            <img src={logo} alt="Logo" />
          </NavLink>
        </figure>
        <button className='toggle-button' onClick={(e) => handleToggleClick(e)}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        <div className="navbar-links">
          <ul onClick={(e) => handleToggleClick(e)}>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li>
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
