import { useRef } from 'react';
import { SearchIcon } from '../../assets/icons';
import './search.css';

const Search = ({ setSearchVal }) => {
  const searchInput = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();

    setSearchVal(() => searchInput.current?.value);
  };

  return (
    <form
      role="search"
      className="search-block"
      onSubmit={handleSubmit}
    >
      <label htmlFor="search-input" className="screen-reader-text"></label>
      <div className="search-wrapper">
        <input
          type="search"
          id="search-input"
          className="search-input"
          ref={searchInput}
          autoComplete="off"
        />
        <button
          type="submit"
          className="button"
          aria-label="Search">
          <SearchIcon />
        </button>
      </div>
    </form>
  );
};

export default Search;
