import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import GlobalContext from '../../contexts/GlobalContext';
import { getPageFromUrl } from '../../utils/utils';
import { useGetPosts } from "../../hooks/useRequest";

const Main = ({ children }) => {
  const { setGlobalContext } = useContext(GlobalContext);
  const location = useLocation();
  let pageNum = getPageFromUrl(location.pathname);
  const { isError, isLoading, data } = useGetPosts(pageNum);

  const posts = data?.posts?.edges || [];
  const { hasNextPage, endCursor } = data?.posts?.pageInfo || {};
  const categories = data?.categories?.nodes || [];

  useEffect(() => {
    if (posts.length > 0) {
      setGlobalContext((oldContext) => ({
        ...oldContext,
        posts,
        categories: categories,
        hasNextPage: hasNextPage,
        cursor: endCursor
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <main className="error"><div>Posts not found!</div></main>;
  }

  return (
    <main>
      {children}
    </main>
  );
};

export default Main;
