import Headline from '../components/utils/Headline';

import './404.css';

const PageNotFound = () => (
    <>
      <section className='header-section'>
        <div className="container">
          <Headline title="404: Page Not Found" />
        </div>
      </section>
      <section className='not-found-content container'>
        <h2 className='error-title'>This page could not be found!</h2>
        <p className='error-text'>We are sorry. But the page you are looking for is not available. <br /> Perhaps you can try a new search.</p>

        <input type='search' placeholder="Search" />

        <button className="button error-btn">Back to home page</button>
      </section>
    </>
);

export default PageNotFound;
